<i18n>
{
  "de": {
    "newPortfolioTitle": "Portfolio erstellen",
    "portfolioNameLabel": "Name",
    "submitButtonLabel": "Erstellen",
    "errorHeader": "Beim Speichern ist ein Fehler aufgetreten"
  }
}
</i18n>

<template>
  <main-layout class="admin-create-portfolio-page">
    <template #default>
      <h1>{{ $t('newPortfolioTitle') }}</h1>
      <div class="error-message" v-if="error">
        <h2>{{ $t('errorHeader') }}</h2>
        <p><b>{{ error }}</b></p>
      </div>
      <form>
        <label for="portfolio-name-field">{{ $t('portfolioNameLabel') }}</label>
        <input id="portfolio-name-field" type="text" v-model="name" v-focus>
        <button-wrapper>
          <button class="button" :disabled="!submitButtonEnabled" @click.prevent="onSubmit">{{ $t('submitButtonLabel') }}</button>
        </button-wrapper>
      </form>
    </template>
  </main-layout>
</template>

<script>
import ButtonWrapper from '@/components/shared/ButtonWrapper'
import MainLayout from '@/pages/layouts/MainLayout'

import { mapActions } from 'vuex'

export default {
  data () {
    return {
      name: null,
      error: null
    }
  },
  components: {
    ButtonWrapper,
    MainLayout
  },
  computed: {
    submitButtonEnabled () {
      return Boolean(this.name)
    }
  },
  methods: {
    ...mapActions({
      addPortfolio: 'portfolio/addPortfolio'
    }),
    async onSubmit () {
      try {
        const portfolio = await this.addPortfolio({
          name: this.name
        })
        this.$router.push({ name: 'adminPortfolioDetails', params: { portfolio_id: portfolio.id } })
      } catch (error) {
        this.error = error.message
      }
    }
  }
}
</script>

<style>
.admin-create-portfolio-page form {
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: baseline;
  justify-content: start;
  gap: var(--spacing-m);
  column-gap: var(--spacing-l);
}

.admin-create-portfolio-page form button {
  grid-column-end: -1;
  justify-self: start;
}

.admin-create-portfolio-page .error-message {
  padding: var(--box-padding);
  border: var(--box-error-border);
  max-width: 800px;
}

.admin-create-portfolio-page .error-message h2 {
  color: var(--error-color);
}

</style>
