<i18n>
{
  "de": {
    "addPortfolio": "Portfolio hinzufügen",
    "uploadFile": "JSON-Datei auswählen:",
    "pendingMessage": "Portfolio wird importiert, dieser Vorgang kann mehrere Minuten dauern. Sie werden zum neuen Portfolio weitergeleitet, sobald der Vorgang abgeschlossen ist."
  }
}
</i18n>
<template>
  <div>
    <h3 v-if="!pending">{{ $t('uploadFile') }}</h3>
    <input id="portfolio-json-field" type="file" accept=".json" @change="onFileChanged" v-if="!pending">
    <div v-else>
      {{ $t('pendingMessage') }}
    </div>
    <div v-if="error">
      {{ error }}
    </div>
  </div>
</template>
<script>
import compassApi from '@/services/compass-api'

export default {
  data () {
    return {
      fileReader: null,
      pending: false,
      error: null
    }
  },
  methods: {
    onFileChanged (event) {
      const file = event.target.files[0]
      this.fileReader = new FileReader()
      this.fileReader.onloadend = this.onFileRead
      this.fileReader.readAsText(file)
    },
    async onFileRead (event) {
      const content = this.fileReader.result
      const parsedObj = JSON.parse(content)
      this.error = null
      this.pending = true
      try {
        const portfolio = await compassApi.call(`/portfolios`, 'POST', parsedObj)
        this.$router.push({ name: 'adminPortfolioDetails', params: { portfolio_id: portfolio.id } })
      } catch (e) {
        this.error = e
      }
      this.pending = false
    }
  }
}
</script>
