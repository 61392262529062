<i18n>
{
  "de": {
    "newPortfolioTitle": "Portfolio importieren"
  }
}
</i18n>

<template>
  <main-layout>
    <template #default>
      <h1>{{ $t('newPortfolioTitle') }}</h1>
      <import-portfolio />
    </template>
  </main-layout>
</template>

<script>
import MainLayout from '@/pages/layouts/MainLayout'

import ImportPortfolio from '@/components/admin-portfolios/ImportPortfolio'

export default {
  data () {
    return {
      name: null,
      error: null
    }
  },
  components: {
    MainLayout,
    ImportPortfolio
  }
}
</script>
